'use client'

import {
  AbortErrorStatusMessage,
  ApiError,
  InternalServerErrorStatusMessage,
  isErrorToLogout,
  StatusErrors,
} from 'libs'
import { ErrorDescription, toast } from 'ui'
import { useTranslations } from 'next-intl'
import { useHelpUserModal } from '@/components/user-help-modal/user-help-modal.store'
import { usePathname } from '@/navigation'
import { useLogout } from '@/app/[locale]/_hooks'
import { WebRoutes } from '../routes'
import { PO_ERROR_CODES } from '../constants/po-errors-code'

export const toastAbortErrorMessage = ({
  title,
  description,
}: {
  title: string
  description: string
}): ReturnType<typeof toast> =>
  toast({
    title,
    description,
    variant: 'destructive',
  })

export const toastErrors = (errors: string[]): ReturnType<typeof toast> => {
  return toast({
    title: 'Error',
    description: <ErrorDescription errors={errors} />,
    variant: 'destructive',
  })
}

type HandlerApiErrorProps = {
  errors: string[]
  message?: string
  statusCode?: number
  errorsDetails?: {
    code: string
    referenceId: string | number
    description: string
  }
}
export const useHandlerApiErrors = () => {
  const { setOpen, setDescription } = useHelpUserModal()
  const translator = useTranslations()
  const pathname = usePathname()
  const { handleLogout } = useLogout()

  const handleApiErrors = (
    { errors, message, errorsDetails, statusCode }: HandlerApiErrorProps,
    options?: { hideSuccessMessage?: boolean; hideErrorMessage?: boolean }
  ): boolean => {
    if (errorsDetails?.code === PO_ERROR_CODES.HELP) {
      setOpen(true)
      setDescription(errorsDetails.description || '')
    }

    if (message === AbortErrorStatusMessage) {
      toastAbortErrorMessage({
        title: translator('global.errorConnection.title'),
        description: translator('global.errorConnection.description'),
      })
      return false
    }

    const isApiErrorToLogout =
      errors instanceof ApiError && isErrorToLogout(errors)

    const isSomeErrorToLogout = [
      StatusErrors.Unauthorized,
      StatusErrors.Forbidden,
    ].some((status) => status.code === statusCode)

    if (
      (isApiErrorToLogout || isSomeErrorToLogout) &&
      pathname !== WebRoutes.Login
    ) {
      void handleLogout(WebRoutes.Login)
      return false
    }

    if (message === InternalServerErrorStatusMessage) {
      toastAbortErrorMessage({
        title: 'Error',
        description: translator('global.wentWrong') || '',
      })
      return false
    }

    if (errors.length) {
      !options?.hideErrorMessage && toastErrors(errors)
      return false
    }

    if (message && !options?.hideSuccessMessage) {
      toast({
        title: message,
      })
    }

    return true
  }

  return {
    handleApiErrors,
  }
}

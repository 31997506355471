import { create } from 'zustand'

export interface HelpUserModalState {
  open: boolean
  description: string
}
export interface HelpUserModalAction {
  setOpen: (open: boolean) => void
  setDescription: (description: string) => void
}

export const useHelpUserModal = create<
  HelpUserModalState & HelpUserModalAction
>((set) => ({
  open: false,
  description: '',
  setOpen: (open) => {
    set(() => {
      if (!open) set(() => ({ description: '' }))
      return { open }
    })
  },
  setDescription: (description) => {
    set(() => ({ description }))
  },
}))

'use client'

import { getNotificationCount, useNotificationCountStore } from 'notifications'
import { memo, type FC } from 'react'
import { useAsync } from 'react-use'
import { Button, NotificationIcon } from 'ui'
import { useHandlerApiErrors } from '@/shared/hooks/use-handler-api-errors'
import { NotificationDropdownMenu } from './notification-dropdown-menu'

type NotificationsMenuProps = {
  accessToken?: string
}
const MemoizedNotificationsMenu: FC<NotificationsMenuProps> = ({
  accessToken,
}) => {
  const { handleApiErrors } = useHandlerApiErrors()
  const { setNotificationCountResponse, notificationCountResponse } =
    useNotificationCountStore()
  const { loading } = useAsync(async () => {
    if (!accessToken || notificationCountResponse?.data) return

    const notificationCount = await getNotificationCount({
      accessToken,
    })

    const isSuccessfully = handleApiErrors(
      {
        errors: notificationCount.errors,
        message: notificationCount.message,
        errorsDetails: notificationCount.errorsDetails,
        statusCode: notificationCount.statusCode,
      },
      {
        hideErrorMessage: true,
        hideSuccessMessage: true,
      }
    )

    if (!isSuccessfully) return

    setNotificationCountResponse(notificationCount)
  }, [])

  if (loading)
    return (
      <Button className="relative font-normal" size="icon" variant="ghost">
        <NotificationIcon />
      </Button>
    )

  return <NotificationDropdownMenu />
}

// export const NotificationsMenu = memo(MemoizedNotificationsMenu)
export const NotificationsMenu = memo(MemoizedNotificationsMenu)

import { LogInIcon } from 'ui'
import { ButtonLink } from '@/components/button-link'
import { FormattedMessage } from '@/components/formatted-message'
import { WebRoutes } from '@/shared'

export const LoginButtonLink = () => {
  return (
    <div className="flex items-center h-full">
      <ButtonLink
        aria-label="Login"
        className="h-[30px] w-[30px] sm:w-max sm:h-max px-0 sm:px-4"
        href={WebRoutes.Login}
      >
        <LogInIcon className="w-5 h-5" />
        <span className="hidden lg:flex">
          <FormattedMessage id="menu.login" />
        </span>
      </ButtonLink>
    </div>
  )
}

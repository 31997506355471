import { useSearchParams } from 'next/navigation'
import { usePathname } from '@/navigation'

export const useParametrizedRoute = () => {
  const pathname = usePathname()
  const params = useSearchParams()

  const getRouteByLocale = (
    locale: string,
    options?: {
      overridePathname?: string
    }
  ) => `/${locale}${options?.overridePathname || pathname}?${params.toString()}`

  const getRouteWithSearchParams = (options?: {
    overridePathname?: string
  }) => {
    return `${options?.overridePathname || pathname}?${params.toString()}`
  }

  return {
    getRouteByLocale,
    getRouteWithSearchParams,
  }
}

'use client'

import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogTrigger,
  ScrollArea,
  VisuallyHidden,
} from 'ui'
import { FormattedMessage } from '../formatted-message'
import { useHelpUserModal } from './user-help-modal.store'

export const UserHelpModal = () => {
  const { open, setOpen, description } = useHelpUserModal()

  return (
    <Dialog onOpenChange={setOpen} open={open}>
      <VisuallyHidden>
        <DialogTrigger>Open</DialogTrigger>
      </VisuallyHidden>
      <DialogContent className="grid place-items-center" showCloseButton>
        <VisuallyHidden>
          <DialogTitle />
        </VisuallyHidden>
        <ScrollArea className="max-h-[50vh] h-full overflow-auto">
          <div dangerouslySetInnerHTML={{ __html: description }} />
        </ScrollArea>
        <Button onClick={() => setOpen(false)}>
          <FormattedMessage id="global.close" />
        </Button>
      </DialogContent>
    </Dialog>
  )
}

'use client'

import dynamic from 'next/dynamic'

export const DynamicCustomerServiceScriptProvider = dynamic(
  () =>
    import('./customer-service-script-provider').then(
      (comp) => comp.CustomerServiceScriptProvider
    ),
  {
    ssr: false,
  }
)

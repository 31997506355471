import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  UserIcon,
} from 'ui'
import { memo } from 'react'
import { FormattedMessage } from '@/components/formatted-message'
import { LogoutButton } from './logout/logout-button'
import { MenuDropdownItems } from './menu-dropdown-items'
import { UserInfo } from './user-info'
import { DeleteAccountDropdownButton } from './logout/delete-account-dropdown-button'

const MemoizedProfileDropdown = () => {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button className="!ring-0 !ring-offset-0 !outline-0 !border-none h-[30px] w-[30px] sm:w-max sm:h-max px-0 sm:px-4">
          <UserIcon className="w-5 h-5" />
          <span className="hidden lg:inline-block">
            <FormattedMessage id="menu.myProfile" />
          </span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        className="flex flex-col gap-1 w-[20.6875rem]"
        sideOffset={15}
      >
        <UserInfo />
        <MenuDropdownItems />
        <DropdownMenuSeparator />
        <DeleteAccountDropdownButton />
        <DropdownMenuItem asChild>
          <LogoutButton />
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

export const ProfileDropdown = memo(MemoizedProfileDropdown)

'use client'

import {
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogTitle,
} from 'ui'
import { useLogoutDialog } from '@/app/[locale]/_hooks'
import { useLogout } from '@/app/[locale]/_hooks/use-logout'
import { FormattedMessage } from '@/components/formatted-message'

export const LogoutDialog = () => {
  const { setOpenDialog, open } = useLogoutDialog()
  const { handleLogout } = useLogout()

  return (
    <Dialog onOpenChange={setOpenDialog} open={open}>
      <DialogContent>
        <DialogTitle>
          <FormattedMessage id="global.closeSession" />
        </DialogTitle>
        <DialogFooter className="flex gap-2">
          <DialogClose asChild>
            <Button className="w-full" type="button" variant="destructive">
              <FormattedMessage id="global.cancel" />
            </Button>
          </DialogClose>
          <DialogClose asChild>
            <Button className="w-full" onClick={() => handleLogout()}>
              <FormattedMessage id="global.accept" />
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

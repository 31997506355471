import type { FC, PropsWithChildren, ReactNode } from 'react'
import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
  LanguageIcon,
  MenuIcon,
  NotificationIcon,
  ServicesIcon,
} from 'ui'
import { cn } from 'utils-tailwindcss'
import { FormattedMessage } from '@/components/formatted-message'
import type { MessagesIntlId } from '@/components/formatted-message'

export const Icons = {
  service: <ServicesIcon />,
  language: <LanguageIcon />,
  notification: <NotificationIcon />,
  menu: <MenuIcon size={20} />,
} as const

type CustomHoverCardProps = PropsWithChildren<{
  text?: MessagesIntlId
  icon: string
  className?: string
}>

export const CustomHoverCard: FC<CustomHoverCardProps> = ({
  children,
  text,
  icon,
  className,
}) => {
  return (
    <HoverCard closeDelay={100} openDelay={100}>
      <HoverCardTrigger asChild>
        <Button
          className={cn('font-normal')}
          size={!text ? 'icon' : 'default'}
          variant="ghost"
        >
          {Icons[icon]}
          {text ? <FormattedMessage id={text} /> : null}
        </Button>
      </HoverCardTrigger>
      <HoverCardContent className={cn('w-auto ', className)} sideOffset={15}>
        {children}
      </HoverCardContent>
    </HoverCard>
  )
}

type CustomDropdownMenuProps = PropsWithChildren<{
  text?: MessagesIntlId
  icon: keyof typeof Icons
  className?: string
  notificationBadge?: ReactNode
  openDialog?: boolean
  showContent?: boolean
  onOpenChange?: (open: boolean) => void
}>
export const CustomDropdownMenu: FC<CustomDropdownMenuProps> = ({
  children,
  text,
  icon,
  className,
  notificationBadge,
  openDialog,
  showContent = true,
  onOpenChange,
}) => {
  return (
    <DropdownMenu onOpenChange={onOpenChange} open={openDialog}>
      <DropdownMenuTrigger asChild>
        <Button
          aria-label="language switcher"
          className="font-normal relative !ring-0 !ring-offset-0 !outline-0 !border-none"
          size={!text ? 'icon' : 'default'}
          variant="ghost"
        >
          {notificationBadge}
          {Icons[icon]}
          {text ? <FormattedMessage id={text} /> : null}
        </Button>
      </DropdownMenuTrigger>
      {showContent && (
        <DropdownMenuContent
          className={cn('w-auto ', className)}
          sideOffset={15}
        >
          {children}
        </DropdownMenuContent>
      )}
    </DropdownMenu>
  )
}

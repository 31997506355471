'use client'

import type { ImageProps } from 'next/image'
import Image from 'next/image'
import { useState } from 'react'
import { cn } from 'utils-tailwindcss'

interface ImageWithFallbackProps extends ImageProps {
  fallback?: ImageProps['src']
  fixFallbackImage?: boolean
}

export const IMAGE_PLACEHOLDER = '/placeholder.webp'
export const NO_PRODUCT_FALLBACK = '/no-product-fallback.webp'
export const FALLBACK_IMAGE = '/image-not-found.webp'

export const ImageWithFallback = ({
  alt,
  src,
  className,
  fallback = NO_PRODUCT_FALLBACK,
  fixFallbackImage,
  ...props
}: ImageWithFallbackProps) => {
  const [srcImage, setSrcImage] = useState(src || NO_PRODUCT_FALLBACK)

  const imageOnErrorHandler = () => {
    setSrcImage(fallback)
  }

  return (
    <Image
      alt={alt}
      blurDataURL={IMAGE_PLACEHOLDER}
      className={cn(className, {
        '!my-auto !h-24': srcImage === fallback && fixFallbackImage,
      })}
      onError={imageOnErrorHandler}
      placeholder="blur"
      src={srcImage}
      {...props}
    />
  )
}

import {
  ContactIcon,
  MapPinIcon,
  MoneyBagIcon,
  PaymentHistoryIcon,
  PaymentIcon,
  PrivacyIcon,
  Separator,
  TermsIcon,
  UserIcon,
  ScanFaceIcon,
} from 'ui'

export const ProfileOptionsIcons = {
  user: <UserIcon size={20} />,
  map: <MapPinIcon size={20} />,
  payment: <PaymentIcon size={20} />,
  contact: <ContactIcon size={20} />,
  separator: <Separator />,
  payment_history: <PaymentHistoryIcon size={20} />,
  terms: <TermsIcon size={20} />,
  privacy: <PrivacyIcon size={20} />,
  money_bag: <MoneyBagIcon size={20} />,
  card: <ScanFaceIcon size={20} />,
}

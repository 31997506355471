'use client'

import { Dialog, DialogContent, Loader } from 'ui'
import { useLoader } from '@/shared'

export const LoaderDialog = () => {
  const isLoading = useLoader((state) => state.isLoading)

  if (!isLoading) return null

  return (
    <Dialog open={isLoading}>
      <DialogContent className="bg-transparent border-0 shadow-none">
        <Loader />
      </DialogContent>
    </Dialog>
  )
}

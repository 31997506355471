import { Button } from 'ui'

export const LoadingButtonMenu = () => {
  return (
    <div className="flex items-center h-full">
      <Button
        className="h-[30px] w-[30px] sm:w-max sm:h-max px-0 sm:px-4 lg:w-28"
        isIcon
        isLoading
      />
    </div>
  )
}

'use client'

import type { ButtonProps } from 'ui'
import { Button, ChevronRightIcon, LogOutIcon } from 'ui'
import { forwardRef } from 'react'
import type { ForwardedRef, MouseEventHandler } from 'react'
import { useLogoutDialog } from '@/app/[locale]/_hooks'
import { FormattedMessage } from '@/components/formatted-message'

export const LogoutButtonWithRef = (
  { onClick, ...rest }: ButtonProps,
  ref: ForwardedRef<HTMLButtonElement>
) => {
  const setOpenLogoutDialog = useLogoutDialog((state) => state.setOpenDialog)

  const handleOpenLogoutDialog: MouseEventHandler<HTMLButtonElement> = (e) => {
    setOpenLogoutDialog(true)
    if (onClick) onClick(e)
  }

  return (
    <Button
      {...rest}
      className="justify-between px-2 text-grey !ring-0 !ring-offset-0 !outline-0 !border-none"
      onClick={handleOpenLogoutDialog}
      ref={ref}
      variant="ghost"
    >
      <div className="flex items-center gap-2">
        <LogOutIcon size={20} />
        <FormattedMessage id="menu.logout" />
      </div>
      <ChevronRightIcon size={20} />
    </Button>
  )
}

export const LogoutButton = forwardRef(LogoutButtonWithRef)

'use client'

import type { NotificationMetaWithImage } from 'notifications'
import { useNotificationStore } from 'notifications'
import { useEffect, useState } from 'react'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from 'ui'
import { ButtonLink } from '@/components/button-link'
import type { MessagesIntlId } from '@/components/formatted-message'
import { FormattedMessage } from '@/components/formatted-message'
import {
  getHistoryRouteByService,
  getServiceKeyText,
  getRouteByService,
  QueryParams,
} from '@/shared'
import { NotificationImage } from './notification-image'

export const NotificationDialog = () => {
  const [openDialog, setOpenDialog] = useState(false)
  const { notification, setNotification } = useNotificationStore()

  useEffect(() => {
    if (notification) {
      setOpenDialog(true)
    }
  }, [notification])

  const handleOpenDialog = (open: boolean) => {
    if (!notification) return
    if (!open) {
      setNotification(null)
    }
    setOpenDialog(open)
  }

  if (!notification) {
    return null
  }

  const { body, title } = notification

  const notificationMeta = notification.meta as NotificationMetaWithImage | null
  const serviceKeyTextButton = getServiceKeyText(
    notificationMeta?.serviceKey ?? ''
  )

  const linkHref = getRouteByService(serviceKeyTextButton ?? '')
  const isNavigateToHistory = !!notificationMeta?.history
  const linkToServiceHistory = getHistoryRouteByService(
    serviceKeyTextButton ?? ''
  )

  return (
    <Dialog modal onOpenChange={handleOpenDialog} open={openDialog}>
      <DialogContent showCloseButton>
        <DialogHeader>
          <DialogTitle className="text-center">{title}</DialogTitle>
        </DialogHeader>
        {!!notificationMeta?.imgUrl && !!title && (
          <div className="w-full mx-auto min-h-[230px] relative">
            <NotificationImage
              image={notificationMeta.imgUrl}
              key={notificationMeta.serviceKey}
              title={title}
            />
          </div>
        )}
        <p className="mx-auto">{body}</p>
        {notificationMeta?.serviceEnabled && (
          <DialogFooter className="!justify-center items-center gap-4">
            {!!serviceKeyTextButton &&
              !!linkToServiceHistory &&
              isNavigateToHistory && (
                <DialogClose asChild>
                  <ButtonLink
                    href={`${linkToServiceHistory}${notificationMeta.serviceDetailsId ? `?${QueryParams.SERVICES_DETAILS_ID}=${notificationMeta.serviceDetailsId}` : ''}`}
                    prefetch={false}
                    variant="outline"
                  >
                    {notificationMeta.butonLabel || (
                      <FormattedMessage id="global.seeOrder" />
                    )}
                  </ButtonLink>
                </DialogClose>
              )}
            {!!serviceKeyTextButton && !!linkHref && !isNavigateToHistory && (
              <DialogClose asChild>
                <ButtonLink href={linkHref} prefetch={false}>
                  <FormattedMessage
                    id={`contact.${serviceKeyTextButton}` as MessagesIntlId}
                  />
                </ButtonLink>
              </DialogClose>
            )}
          </DialogFooter>
        )}
      </DialogContent>
    </Dialog>
  )
}

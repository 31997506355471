import { useAsync } from 'react-use'
import { getProfile, useProfileStore } from '@/services'
import { useHandlerApiErrors } from '@/shared/hooks/use-handler-api-errors'

export const useGetProfileData = ({
  accessToken,
  showProtectedLinks,
}: {
  showProtectedLinks?: boolean
  accessToken?: string
}) => {
  const { handleApiErrors } = useHandlerApiErrors()
  const { profileData, setProfileData } = useProfileStore((state) => ({
    profileData: state.user,
    setProfileData: state.updateUser,
  }))

  const { loading } = useAsync(async () => {
    if (!accessToken || profileData || !showProtectedLinks) return

    const { data, ...rest } = await getProfile({
      accessToken,
    })

    const isSuccessfully = handleApiErrors(rest, {
      hideErrorMessage: true,
      hideSuccessMessage: true,
    })

    if (!isSuccessfully) return

    setProfileData(data)
  }, [])

  return {
    states: { loading, profileData },
  }
}

import { Fragment } from 'react'
import { ChevronRightIcon, DropdownMenuItem, DropdownMenuSeparator } from 'ui'
import { ButtonLink } from '@/components/button-link'
import { FormattedMessage } from '@/components/formatted-message'
import { ProfileOptionsIcons } from '@/components/profile-options-icons'
import type { ProfileMenuItem } from '@/services'
import { ProfileMenuItems } from '@/services'

export const MenuDropdownItems = () => {
  return ProfileMenuItems.map((item: ProfileMenuItem) => (
    <Fragment key={item.value}>
      {item.title === 'separator' ? (
        <DropdownMenuSeparator />
      ) : (
        <DropdownMenuItem asChild>
          <ButtonLink
            className="cursor-pointer justify-between py-0 !ring-0 !ring-offset-0 !outline-0 !border-none text-grey"
            href={item.href}
            variant="ghost"
          >
            <div className="flex items-center gap-2">
              {ProfileOptionsIcons[item.icon]}
              <FormattedMessage id={item.title} />
            </div>
            <ChevronRightIcon size={20} />
          </ButtonLink>
        </DropdownMenuItem>
      )}
    </Fragment>
  ))
}

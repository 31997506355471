'use client'

import type { FC } from 'react'
import { useGetProfileData } from '@/default/profile/_hooks/use-get-profile-data'
import { BalanceBadgeLink } from './balance-badge-link'
import { LoadingButtonMenu } from './loading-button-menu'
import { LoginButtonLink } from './login-button-link'
import { ProfileDropdown } from './profile-dropdown'

type MenuProps = {
  showProtectedLinks?: boolean
  accessToken?: string
}

export const ProfileMenu: FC<MenuProps> = ({
  accessToken,
  showProtectedLinks,
}) => {
  const {
    states: { loading, profileData },
  } = useGetProfileData({ accessToken, showProtectedLinks })

  if (loading) return <LoadingButtonMenu />

  if (showProtectedLinks && profileData?.user) {
    return (
      <div className="flex items-center h-full gap-1 sm:gap-4">
        <BalanceBadgeLink balance={profileData.user.balance} />
        <ProfileDropdown />
      </div>
    )
  }

  return <LoginButtonLink />
}

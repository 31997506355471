import { Avatar, AvatarFallback } from 'ui'
import { useProfileStore } from '@/services'
import { isEmpty } from '@/shared'

export const UserInfo = () => {
  const user = useProfileStore((state) => state.user?.user)

  if (!user || isEmpty(user)) return null

  return (
    <div className="flex items-center gap-4">
      <Avatar className="w-16 h-16">
        <AvatarFallback className="font-bold">
          {user.firstName.charAt(0).toUpperCase()}
          {user.lastName?.charAt(0).toUpperCase()}
        </AvatarFallback>
      </Avatar>
      <div className="flex flex-col text-grey">
        <h6 className="text-lg font-bold text-black">
          {user.firstName} {user.lastName}
        </h6>
      </div>
    </div>
  )
}

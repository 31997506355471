'use client'

import { useEffect } from 'react'
import { useLocalStorage } from 'react-use'
import pkg from '../../../../../package.json'
import { useClearCacheStorage } from '../../_hooks/use-clear-cache-storage'

export const packageVersion = pkg.version

export const getVersion = () => packageVersion

export const VersionProvider = () => {
  const [version, setVersion] = useLocalStorage<string>('version')
  const { clearCacheStorage } = useClearCacheStorage()
  const currentVersion = getVersion()

  useEffect(() => {
    if (version !== currentVersion) {
      clearCacheStorage({ notRemoveFcm: true })
      setVersion(getVersion())
      window.location.reload()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [version, currentVersion])

  return null
}

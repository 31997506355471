export const enum PO_ERROR_CODES {
  PO_01 = 'PO-01',
  PO_02 = 'PO-02',
  PO_03 = 'PO-03',
  PO_04 = 'PO-04',
  PO_05 = 'PO-05',
  PO_06 = 'PO-06',
  PO_07 = 'PO-07',
  PO_08 = 'PO-08',
  PO_09 = 'PO-09',
  PO_10 = 'PO-10',
  HELP = 'HELP',
}

'use client'

import type { ReactNode } from 'react'
import { Button, Dialog, DialogClose, DialogContent, HistoryIcon } from 'ui'
import { ButtonLink } from '@/components/button-link'
import { FormattedMessage } from '@/components/formatted-message'
import { usePathname } from '@/navigation'
import {
  useOpenServiceConfigDialog,
  useServiceConfigStore,
} from '@/shared/hooks/use-service-confirm-dialog'

export const ServicesConfirmDialog = () => {
  const pathname = usePathname()
  const { onOpenDialog, openDialog } = useOpenServiceConfigDialog()
  const { setServiceType, services, serviceType } = useServiceConfigStore()

  if (!serviceType || !services) return null

  const {
    message,
    navigateTo = '',
    serviceEnabled,
    historyLink,
  } = services[serviceType as string]

  const isSamePageToNavigate = pathname.includes(navigateTo)

  if (serviceEnabled && !message) return null

  const handleOpenDialog = (open: boolean) => {
    if (serviceEnabled && !message) return

    if (pathname === navigateTo && open && !serviceEnabled) return

    if (!open) setServiceType(null)

    onOpenDialog(open)
  }

  let button = (
    <Button>
      <FormattedMessage id="global.accept" />
    </Button>
  )
  let historyButton: ReactNode | null = null

  if (serviceEnabled && message && !isSamePageToNavigate && navigateTo) {
    button = (
      <ButtonLink href={navigateTo}>
        <FormattedMessage id="global.goToService" />
      </ButtonLink>
    )
  }

  if (serviceEnabled && historyLink) {
    historyButton = (
      <DialogClose asChild>
        <ButtonLink
          className="items-center "
          href={historyLink as string}
          variant="outline"
        >
          <HistoryIcon /> <FormattedMessage id="global.seeHistory" />
        </ButtonLink>
      </DialogClose>
    )
  }

  return (
    <Dialog onOpenChange={handleOpenDialog} open={openDialog}>
      <DialogContent className="w-[90%] md:w-full">
        {message && (
          <div className="flex flex-col items-center justify-center">
            <h4 className="text-xl font-semibold text-center">{message}</h4>
          </div>
        )}
        <div className="flex items-center justify-center gap-4">
          {historyButton}
          <DialogClose asChild>{button}</DialogClose>
        </div>
      </DialogContent>
    </Dialog>
  )
}

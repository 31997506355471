'use client'

import dynamic from 'next/dynamic'
import { Button, MenuIcon, ServicesIcon } from 'ui'
import { FormattedMessage } from '@/components/formatted-message'

export const DynamicServicesMenu = dynamic(
  () => import('./services-menu').then((comp) => comp.ServicesMenu),
  {
    loading: () => (
      <>
        <Button
          className="flex font-normal lg:hidden"
          size="icon"
          variant="ghost"
        >
          <MenuIcon size={20} />
        </Button>
        <Button className="hidden font-normal lg:flex" variant="ghost">
          <ServicesIcon />
          <FormattedMessage id="menu.services" />
        </Button>
      </>
    ),
  }
)

'use client'

import { setCookie } from 'cookies-next'
import { useLocale } from 'next-intl'
import { useEffect, type HTMLAttributes } from 'react'
import { DropdownMenuItem, EsIcon, UsIcon } from 'ui'
import { cn } from 'utils-tailwindcss'
import { useHandlerTokens } from '@/app/[locale]/_hooks'
import { locales } from '@/get-locale'
import { saveDefaultLanguage } from '@/services'
import { LOCALE_COOKIE } from '@/shared/constants'
import { useParametrizedRoute } from '@/shared/hooks/use-parametrized-route'
import { CustomDropdownMenu } from './custom-hover-card'

const Flags = {
  es: <EsIcon />,
  en: <UsIcon />,
}

export function LanguageSwitcher(
  props: Omit<HTMLAttributes<HTMLElement>, 'children'>
) {
  const currentLocale = useLocale()
  const { getRouteByLocale } = useParametrizedRoute()
  const { getAccessToken } = useHandlerTokens({ noLogout: true })

  useEffect(() => {
    setCookie(LOCALE_COOKIE, currentLocale)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleLocaleChange = async (locale: string) => {
    setCookie(LOCALE_COOKIE, locale)

    const accessToken = await getAccessToken()

    if (accessToken) {
      await saveDefaultLanguage({ accessToken, body: { defaultLan: locale } })
    }
  }

  return (
    <CustomDropdownMenu className="min-w-full px-0" icon="language">
      <nav {...props}>
        <ul className="flex flex-col gap-4">
          {locales.map((locale) => (
            <li key={locale}>
              <DropdownMenuItem asChild>
                <a
                  className={cn(
                    'hover:outline-none hover:bg-accent p-2 px-3 text-black font-normal !ring-0 !ring-offset-0 !outline-0 !border-none flex items-center gap-2',
                    {
                      'font-bold bg-accent': currentLocale === locale,
                    }
                  )}
                  href={getRouteByLocale(locale)}
                  onClick={() => handleLocaleChange(locale)}
                >
                  {Flags[locale]}
                  {locale.toUpperCase()}
                </a>
              </DropdownMenuItem>
            </li>
          ))}
        </ul>
      </nav>
    </CustomDropdownMenu>
  )
}

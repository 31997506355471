import { type FC } from 'react'
import {
  FALLBACK_IMAGE,
  ImageWithFallback,
} from '@/components/image-with-fallback'

type NotificationImageProps = {
  image: string
  title: string
}

export const NotificationImage: FC<NotificationImageProps> = ({
  image,
  title,
}) => {
  return (
    <ImageWithFallback
      alt={title}
      className="w-full max-w-[400px] !h-[230px] top-0 z-10 mx-auto object-contain"
      fallback={FALLBACK_IMAGE}
      fill
      fixFallbackImage
      src={image}
    />
  )
}

'use client'

import { useAutoAnimate } from '@formkit/auto-animate/react'
import { enUS, es } from 'date-fns/locale'
import { useLocale } from 'next-intl'
import {
  NotificationDropdown,
  NotificationInfoBlock,
  useNotificationCountStore,
  useNotificationStore,
} from 'notifications'
import { captureException } from '@/shared'
import { FormattedMessage } from '@/components/formatted-message'
import { useHandlerTokens } from '@/app/[locale]/_hooks'

export const NotificationDropdownMenu = () => {
  const notificationCountResponse = useNotificationCountStore(
    (state) => state.notificationCountResponse
  )
  const { getAccessToken } = useHandlerTokens({
    onError: (error) => captureException(error),
  })
  const [parent] = useAutoAnimate()
  const setNotification = useNotificationStore((state) => state.setNotification)
  const locale = useLocale()
  const dateLocale = locale === 'en' ? enUS : es

  return (
    <NotificationDropdown
      appHeader="web"
      getAccessToken={getAccessToken}
      loadMoreButtonText={FormattedMessage({ id: 'menu.seeAll' })}
      locale={locale as 'es' | 'en'}
      noNotificationsText={FormattedMessage({ id: 'menu.noNotifications' })}
      notificationCount={notificationCountResponse}
    >
      {({
        notifications,
        handleDeleteNotification,
        handleOpenNotification,
      }) => (
        <div ref={parent}>
          {notifications.map((notification) => (
            <NotificationInfoBlock
              handleDeleteNotification={handleDeleteNotification}
              handleOpenNotification={handleOpenNotification}
              key={notification.id}
              locale={dateLocale}
              notification={notification}
              setNotification={setNotification}
            />
          ))}
        </div>
      )}
    </NotificationDropdown>
  )
}

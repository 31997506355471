'use client'

import { logout } from 'authentication'
import { useEffect } from 'react'
import { usePathname } from '@/navigation'
import { useRegisterStore } from '@/services'
import { WebRoutes } from '@/shared'

export const ClearRegisterStoreProvider = () => {
  const {
    resetRegisterState,
    currentStep,
    accessToken: registerAccessToken,
  } = useRegisterStore()

  const pathname = usePathname()
  const isRegisterPage = pathname === WebRoutes.Register

  useEffect(() => {
    if (isRegisterPage) return

    const handleLogout = async (accessToken: string) => {
      await logout({ appHeader: 'web', accessToken })
      resetRegisterState()
    }

    if (registerAccessToken && currentStep > 1) {
      void handleLogout(registerAccessToken)
    }
  }, [isRegisterPage, resetRegisterState, registerAccessToken, currentStep])

  return null
}

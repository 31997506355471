import { useState, useTransition } from 'react'
import { useHandlerTokens, useLogout } from '@/app/[locale]/_hooks'
import { deleteAccount } from '@/services'
import { useHandlerApiErrors } from '@/shared/hooks/use-handler-api-errors'

export const useDeleteAccount = () => {
  const [openDialog, setOpenDialog] = useState(false)
  const [isPending, startTransition] = useTransition()
  const { handleApiErrors } = useHandlerApiErrors()
  const { getAccessToken } = useHandlerTokens()
  const { handleLogout } = useLogout()

  const handleOpenDialog = (open: boolean) => {
    if (isPending) return

    setOpenDialog(open)
  }

  const handleDeleteAccount = () => {
    startTransition(async () => {
      const accessToken = await getAccessToken()
      if (!accessToken) return

      const { errors, statusCode, message, errorsDetails } =
        await deleteAccount({
          accessToken,
        })

      const isSuccessfully = handleApiErrors({
        errors,
        errorsDetails,
        message,
        statusCode,
      })

      if (!isSuccessfully) return

      await handleLogout()
    })
  }

  return {
    states: {
      openDialog,
      isPending,
    },
    actions: {
      handleOpenDialog,
      handleDeleteAccount,
    },
  }
}

import { create } from 'zustand'
import { persist } from 'zustand/middleware'
import type {
  Cart,
  Category,
  Shipping,
  ShippingErrorDetails,
} from './shop.types'

export const enum StoreSteps {
  CART_RESUME = 1,
  SHIPPING_DETAILS,
  SENDER_INFO,
  IDENTITY_DOCUMENTS,
  BENEFICIARY,
  RESUME,
}
export type ShopState = {
  currentStep: StoreSteps
  receiveContactId?: number | null
  documentIdentityId?: number | null
  shipping?: Shipping | null
  cart: Cart | null
  categories: Category[] | null
  missingShipmentProducts: string[]
  loadingCategories: boolean
  shippingErrorDetails?:
    | (ShippingErrorDetails & {
        errors: string[]
      })
    | null
}

export type ShopActions = {
  setCurrentStep: (currentStep: ShopState['currentStep']) => void
  setReceiveContactId: (receiveContactId: ShopState['receiveContactId']) => void
  setShipping: (shipping: ShopState['shipping']) => void
  setCart: (cart: ShopState['cart']) => void
  setDocumentIdentityId: (
    documentIdentityId: ShopState['documentIdentityId']
  ) => void
  resetShopCart: () => void
  setCategories: (categories: ShopState['categories']) => void
  setMissingShipmentProducts: (
    missingShipmentProducts: ShopState['missingShipmentProducts']
  ) => void
  setShippingErrorDetails: (
    errorDetails: ShopState['shippingErrorDetails'] | null
  ) => void
  setLoadingCategories: (
    loadingCategories: ShopState['loadingCategories']
  ) => void
}

const defaultStore: ShopState = {
  currentStep: 1,
  cart: null,
  receiveContactId: null,
  shipping: null,
  documentIdentityId: null,
  categories: null,
  missingShipmentProducts: [],
  loadingCategories: false,
}

export const useShopStore = create<ShopState & ShopActions>()(
  persist(
    (set) => ({
      ...defaultStore,
      setCart: (cart) => set({ cart }),
      setCurrentStep: (currentStep) => set({ currentStep }),
      setReceiveContactId: (receiveContactId) => set({ receiveContactId }),
      setShipping: (shipping) => set({ shipping }),
      setDocumentIdentityId: (documentIdentityId) =>
        set({ documentIdentityId }),
      resetShopCart: () => set({ ...defaultStore }),
      setCategories: (categories) => set({ categories }),
      setMissingShipmentProducts: (missingShipmentProducts) =>
        set({ missingShipmentProducts }),
      setLoadingCategories: (loadingCategories) => set({ loadingCategories }),
      setShippingErrorDetails: (shippingErrorDetails) =>
        set({ shippingErrorDetails }),
    }),
    { name: 'shop' }
  )
)

import {
  Button,
  ChevronRightIcon,
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTrigger,
  DropdownMenuItem,
  Trash2Icon,
} from 'ui'
import { FormattedMessage } from '@/components/formatted-message'
import { useDeleteAccount } from '../../_hooks/use-delete-account'

export const DeleteAccountDropdownButton = () => {
  const {
    actions: { handleDeleteAccount, handleOpenDialog },
    states: { isPending, openDialog },
  } = useDeleteAccount()

  return (
    <Dialog onOpenChange={handleOpenDialog} open={openDialog}>
      <DialogTrigger asChild>
        <DropdownMenuItem asChild onSelect={(e) => e.preventDefault()}>
          <Button
            className="justify-between  text-grey !ring-0 !ring-offset-0 !outline-0 !border-none cursor-pointer"
            variant="ghost"
          >
            <span className="flex items-center gap-2">
              <Trash2Icon size={20} />
              <FormattedMessage id="menu.deleteAccount" />
            </span>
            <ChevronRightIcon size={20} />
          </Button>
        </DropdownMenuItem>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader className="text-lg font-bold">
          <FormattedMessage id="register.deleteAccountInfo.title" />
        </DialogHeader>
        <DialogDescription className="text-base">
          <FormattedMessage id="register.deleteAccountInfo.description" />
        </DialogDescription>
        <DialogFooter className="gap-2 md:gap-0">
          <DialogClose asChild>
            <Button disabled={isPending} variant="outline">
              <FormattedMessage id="global.cancel" />
            </Button>
          </DialogClose>
          <Button isLoading={isPending} onClick={handleDeleteAccount}>
            <FormattedMessage id="global.accept" />
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

'use client'

import pkg from '../../../../../package.json'

export const packageVersion = pkg.version

export const getVersion = () => packageVersion

export const Version = () => {
  const currentVersion = getVersion()

  return <span>{currentVersion}</span>
}

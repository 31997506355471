import type { FC } from 'react'
import { Badge } from 'ui'
import { formatCurrencyUSD, WebRoutes } from '@/shared'
import { Link } from '@/navigation'

type BalanceBadgeLinkProps = {
  balance: number
}

export const BalanceBadgeLink: FC<BalanceBadgeLinkProps> = ({ balance }) => {
  return (
    <Link href={WebRoutes.ProfileBalance}>
      <Badge
        className="font-bold transition-all hover:bg-primary/90 hover:text-white"
        variant="outline"
      >
        {formatCurrencyUSD(balance)}
      </Badge>
    </Link>
  )
}

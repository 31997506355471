'use client'

import dynamic from 'next/dynamic'

export const DynamicPaymentStatusProvider = dynamic(
  () =>
    import('./payment-status-provider').then(
      (comp) => comp.PaymentStatusProvider
    ),
  { ssr: false }
)

'use client'

import dynamic from 'next/dynamic'

export const DynamicEventsProvider = dynamic(
  () => import('./events-provider').then((comp) => comp.EventsProvider),
  {
    ssr: false,
  }
)
